<template>
  <Waterfall :lazyload="false" :breakpoints="breakpoints" :list="productList">
    <template #item="{ item }">
      <div class="card_content" @click="goInfo(item.id)">
        <div class="card_img" >
          <img :src="'https://admin.wukongbc.com/' + item.thumb" class="cover" />
        </div>
        <div class="content">
          <div class="store">{{ item.title }}</div>
          <div class="time">{{ format(item.create_time * 1000, 'yyyy-MM-dd') }}</div>
          <div class="title">{{ item.description }}</div>
        </div>
      </div>
    </template>
  </Waterfall>
</template>

<script>
import router from '@/router'
import {format} from '@/utils/utils'
import { computed, ref } from 'vue'
import { LazyImg, Waterfall } from 'vue-waterfall-plugin-next'
import 'vue-waterfall-plugin-next/dist/style.css'
export default {
  props: {
    productList: Array,
    cateId: [Number, String]
  },
  components: {
    // LazyImg,
    Waterfall
  },
  setup(props) {
    const list = ref()
    const breakpoints = ref({
      1920: {
        rowPerView: 1
      }
    })
    const goInfo = (id) => {
      router.push({
        path:'/newInfo',
        query: {
          id: id,
          cateId: props.cateId
        }
      })
    }

    return {
      breakpoints,
      list,
      goInfo,
      format
    }
  }
}
</script>

<style lang="less" scoped>
.waterfall-list {
  background: #f8f8f8;
}
.card_content {
  padding: 44px 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  box-sizing: border-box;

  .card_img {
    margin-right: 40px;
    .cover {
      border-radius: 30px;
      width: 500px;
      height: 305px;
      background: #000;
    }
    // margin-bottom: 7px;
    &.active {
      border: 1px solid #e7e7e7;
    }
    // :deep(.lazy__img) {
    //   width: 100%;
    //   border-radius: 4px;
    //   font-size: 0;
    //   height: 100%;
    // }
  }
  .content {
    flex: 1;
    padding: 0 8px;
    .store {
      color: #000;
      font-size: 28px;
      font-weight: 600;
      line-height: 50px;
    }
    .time {
      color: #000;
      font-size: 20px;
      line-height: 60px;
    }
    .title {
      color: #707070;
      font-size: 18px;
      font-weight: 400;
      line-height: 35px;
    }
  }
}
.card_content + .card_content {
  margin-top: 40px;
}
</style>
